import clsx from 'clsx'

const FONT = {
  default: 'font-1',
}
const SIZE = {
  default: 'text-17 sm:text-18',
  small: 'text-15 sm:text-16',
}
const SPACING = {
  default: 'tracking-wide',
}
const LEADING = {
  default: 'leading-none',
}
const WEIGHT = {
  default: 'font-medium',
}
const ALIGN = {
  default: '',
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
  justify: 'text-justify',
}

export function buttonBox({
  className = '',
  font = 'default',
  size = 'default',
  spacing = 'default',
  leading = 'default',
  weight = 'default',
  align = 'default',
} = {}) {
  return clsx(
    FONT[font],
    SIZE[size],
    SPACING[spacing],
    LEADING[leading],
    WEIGHT[weight],
    ALIGN[align],
    className,
  )
}
