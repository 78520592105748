import { h, render } from 'preact'
import { OrderForm } from './order-form'

const preactRoot = document.querySelector('#preact-root')
const getContact = document.querySelector('#get-contact')

const closeModal = () => {
  document.body.classList.remove('overflow-hidden', 'w-full', 'h-full')
}

const openModal = () => {
  document.body.classList.add('overflow-hidden', 'w-full', 'h-full')
}

document.addEventListener('click', function (e) {
  if (e.target.closest('.get-order-btn')) {
    openForm()
  } else if (e.target.closest('#detail-order-close')) {
    closeModal()
    render(null, preactRoot)
    isRendered = false
  } else if (e.target.closest('.get-contact-btn')) {
    openModal()
    getContact?.classList.remove('hidden')
  } else if (e.target.closest('#get-contact-close')) {
    closeModal()
    getContact?.classList.add('hidden')
  }
})

let isRendered = false

document.addEventListener('keydown', function (e) {
  if (e.key == 'Escape') {
    if (isRendered) {
      closeModal()
      render(null, preactRoot)
    }
    if (getContact && !getContact.classList.has('hidden')) {
      closeModal()
      getContact.classList.add('hidden')
    }
  }
})

export function openForm(props = {}) {
  openModal()
  render(<OrderForm id={window.__initial_state__.car} {...props} />, preactRoot)
  isRendered = true
}
