import { useQuery } from '@tanstack/react-query'

export function useCalculate(form) {
  return useQuery({
    queryKey: ['calculate'],
    queryFn: async () => {
      if (!form.current) return null
      const response = await fetch('/api/calculator/calculate/', {
        method: 'POST',
        body: new FormData(form.current),
      })
      if (!response.ok) {
        return Promise.reject(response)
      }
      return response.json()
    },
  })
}
