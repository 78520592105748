import $ from 'jquery'

window.addEventListener('popstate', () => {
  jQueryRefresh(window.location.toString())
})

$(document)
  .on('click', 'a.js-carbox', event => {
    push(event.target.href, event)
  })
  .on('change', 'select.js-carbox', event => {
    push(event.target.value, event)
  })

function push(url, event) {
  jQueryRefresh(url)
  window.history.pushState(undefined, '', url)
  event.preventDefault()
}

function jQueryRefresh(url) {
  $.get(url).then(text => {
    const html = $(text)
    $('#carbox').replaceWith(html.find('#carbox'))
    document.title = html.filter('title').text()
    document.getElementById('carbox').scrollIntoView({ block: 'start', behaviour: 'smooth ' })
  })
}
