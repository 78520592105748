import { h, Fragment } from 'preact'
import { text } from './text'

export function Label({ children, required, className = '', ...props }) {
  return (
    <label
      class={text({
        size: 'smaller',
        weight: 'medium',
        className: 'block mb-1 ' + className,
      })}
      {...props}
    >
      {children} {required ? <span class="text-orange-500">*</span> : null}
    </label>
  )
}

export function Input({ className = '', ...props }) {
  return (
    <input
      class={text({
        size: 'form',
        leading: 'none',
        className:
          'py-2 px-4 border-[1px] rounded border-solid border-gray-400 bg-gray-50 w-full ' +
          className,
      })}
      {...props}
    />
  )
}

export function FormInput({ className, label, ...props }) {
  return (
    <div class={className}>
      <Label required={props.required} htmlFor={props.name}>
        {label}
      </Label>
      <Input {...props} id={props.name} />
    </div>
  )
}

export function Select({ className = '', ...props }) {
  return (
    <select
      class={text({
        size: 'form',
        leading: 'none',
        className:
          'py-2 px-4 border-[1px] rounded border-solid border-gray-400 bg-gray-50 w-full ' +
          className,
      })}
      {...props}
    />
  )
}

export function FormSelect({ label, className, ...props }) {
  return (
    <div class={className}>
      <Label required={props.required} htmlFor={props.name}>
        {label}
      </Label>
      <Select {...props} id={props.name} />
    </div>
  )
}

const TIMES = []
for (let i = 7; i <= 16; i++) {
  TIMES.push(`${i}:00`)
  TIMES.push(`${i}:30`)
}

export function FormTimeSelect({ children, ...props }) {
  return (
    <FormSelect {...props}>
      {children}
      <option value="">Vyberte</option>
      {TIMES.map((time, index) => (
        <option key={time} value={time}>
          {time} - {TIMES[index + 1]}
        </option>
      )).slice(0, -2)}
    </FormSelect>
  )
}
