import { h, Fragment } from 'preact'
import { useRef, useState } from 'preact/hooks'
import { subtitle } from './subtitle'
import { text } from './text'
import { buttonBox } from './button-box'
import { button } from './button'
import cars from '../../../shared/cars.json'
import { QueryClientProvider, useMutation } from '@tanstack/react-query'
import { queryClient } from './query-client'
import { FormInput, FormSelect, FormTimeSelect } from './components'
import { czkFormatter, pluralizeDays } from './utils'
import { useCalculate } from './use-calculate'
import { QRCodeComponent } from './qr-code'

function Price({ code, price }) {
  if (code === 'UNKNOWN_CITY' || code === 'INVALID_DURATION') {
    return (
      <div class="px-4 py-2 bg-red-500 rounded mb-2">
        <p
          class={text({
            size: 'small',
            weight: 'semibold',
            color: 'white',
          })}
        >
          Požadavek na získání ceny zamítnut.
        </p>
      </div>
    )
  }

  if (code === 'NO_CAR') return null

  if (code === 'NO_DURATION') {
    return (
      <div class="px-4 py-2 bg-orange-100 rounded mb-2">
        <p class={text({ size: 'small', weight: 'semibold' })}>
          Cena od {czkFormatter.format(price)}/den{' '}
        </p>
      </div>
    )
  }

  const suffix = () =>
    price.isWeekend
      ? 'víkendová akce'
      : `${czkFormatter.format(price.day)}/den na ${price.days} ${pluralizeDays(price.days)}`

  if (code === 'NO_CITY') {
    return (
      <>
        <div class="px-4 py-2 bg-orange-100 rounded mb-2">
          <p class={text({ size: 'small', weight: 'semibold' })}>
            Základní cena od {czkFormatter.format(price.total)} ({suffix()}).
          </p>
        </div>
        <p class={text({ size: 'small', weight: 'semibold', className: 'text-red-500 px-4 py-2' })}>
          Zvolte prosím místo vyzvednutí.
        </p>
      </>
    )
  }

  if (code !== 'OK') {
    return (
      <div class="px-4 py-2 bg-red-500 rounded mb-2">
        <p class={text({ size: 'small', weight: 'semibold', color: 'white' })}>
          Došlo k neznámé chybě, nebylo možné spočítat cenu.
        </p>
      </div>
    )
  }

  return (
    <div class="flex justify-between items-center px-4 py-2 bg-orange-100 rounded mb-2">
      <p class={text({ size: 'small', weight: 'semibold' })}>
        Cena {czkFormatter.format(price.total)} ({suffix()}).
      </p>
    </div>
  )
}

function Modal({ children }) {
  return (
    <>
      <div class="absolute inset-0 overflow-hidden">
        <div class="w-[1520px] h-[1340px] translate-x-[-20%] translate-y-[0px] rotate-[-30deg] top-1/2 left-1/2 origin-center absolute rounded-full bg-[#79DFFF] bg-opacity-50 mix-blend-normal blur-[204px]"></div>
      </div>
      <div class="fixed inset-0 md:py-8 md:px-16 lg:px-20 md:overflow-scroll z-50 box-border bg-gray-700 bg-opacity-60 backdrop-blur">
        <div
          class="flex justify-center items-center min-h-full w-full mx-auto"
          style="max-width:840px;"
        >
          <div class="max-h-full relative flex h-auto w-full justify-center opacity-0 anim-open">
            <div class="h-screen md:h-full w-full overflow-auto bg-white rounded">{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}

function Form({ returnUrl = window.location.href, id = null, dateFrom, dateTo, timeFrom, timeTo, cityFrom, otherCity }) {
  const form = useRef(null)
  const [minDate, setMinDate] = useState(() => dateFrom || new Date().toISOString().slice(0, 10))
  const { data, isFetching, refetch } = useCalculate(form)
  const [selectedCity, setSelectedCity] = useState('');

  const {
    mutate,
    data: result,
    isSuccess,
    isLoading,
  } = useMutation({
    mutationFn: async body => {
      const response = await fetch('/kontakt/', {
        method: 'POST',
        body,
      })
      if (!response.ok) {
        return Promise.reject(response)
      }
      return response.json()
    },
  })

  const onSubmit = event => {
    event.preventDefault()
    mutate(new FormData(event.currentTarget))
  }

  const [selectedType, setSelectedType] = useState('individual')
  const isCompanyForm = ['company', 'company-osvc'].includes(selectedType)

  const handleFormTypeClick = type => {
    setSelectedType(type)
  }

  const getButtonStyle = type => {
    if (selectedType === type) {
      return { backgroundColor: '#fc5000', color: '#ffffff' }
    } else {
      return {}
    }
  }

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };


  if (isSuccess && (result.code === 'OK' || result.code === 'SEND_INVOICE_FAILED')) {
    return (
      <Modal key="success">
        <div class="flex items-center justify-between px-8 py-5 border-b-[1px] border-solid border-gray-400 bg-gray-100 sticky bg-white top-0">
          <h3 class={subtitle()}>Poptávka odeslána</h3>
          <p id="detail-order-close" class={text({ className: 'hover:underline cursor-pointer' })}>
            zavřít
          </p>
        </div>
        <div class="px-8 py-16 flex items-center">
          <div class="w-full">
            {result.code === 'SEND_INVOICE_FAILED' && (
              <p class={text({ className: 'mb-6' })}>
                Byla vygenerována faktura, ale selhalo odeslání emailem, odkaz na webovou verzi:{' '}
                <a href={result.invoice} target="_blank">
                  {result.invoice}
                </a>
              </p>
            )}
            {result.code === 'OK' && (
              <>
                <p
                  class={text({
                    size: 'medium',
                    weight: 'medium',
                    align: 'center',
                    className: 'mb-2',
                  })}
                >
                  Na Váš email byla odeslána nabídka pronájmu s možností rezervace uhrazením zálohy dle dokladu v příloze.
                </p>
                <p
                  class={text({
                    size: 'medium',
                    color: 'red',
                    weight: 'medium',
                    align: 'center',
                    className: 'mb-5',
                  })}
                >
                  Při uhrazení zálohy na pronájem se stává rezervace závaznou a zálohy jsou
                  nevratné.
                  <br />
                  <strong class="font-semibold">
                    Dbejte datu splatnosti - po pozdním uhrazení (po splatnosti) může být už vozidlo
                    obsazeno.
                  </strong>
                </p>
                <p
                  class={text({
                    size: 'medium',
                    weight: 'medium',
                    align: 'center',
                    className: 'mb-4',
                  })}
                >
                  Platbu můžete uhradit již nyní:
                </p>
                {isSuccess && result.code === 'OK' && (
                  <div class="mb-10 text-center">
                    <p
                      class={text({
                        size: 'small',
                        weight: 'bold',
                        className: 'py-1 px-10 bg-gray-200 rounded mb-4 inline-block',
                      })}
                    >
                      Rychlá QR platba:
                    </p>
                    <QRCodeComponent
                      priceTotal={result.deposit}
                      invoiceVS={result.invoicevs}
                      iban={result.invoiceiban}
                    />
                  </div>
                )}
                <div class={buttonBox({ align: 'center' })}>
                  <a href={result.invoice} target="_blank" class={button({ className: 'w-full' })}>
                    Přejít k platbě
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal key="form">
      <form action="/kontakt/" method="post" id="form_order" ref={form} onChange={refetch} onSubmit={onSubmit}>
        <input type="hidden" name="_return_url" value={`${returnUrl}#order`} />
        <input type="hidden" name="_return_json" value={1} />
        <div style="height: 0px; left: 0px; opacity: 0; position: absolute; top: 0px; width: 0px; z-index: -1;">
          <input
            type="checkbox"
            name="contact_me_by_fax_only"
            tabindex="-1"
            autocomplete="off"
            value="1"
          />
        </div>
        <div class="flex items-center justify-between px-8 py-5 border-b-[1px] border-solid border-gray-400 bg-gray-100 sticky bg-white top-0">
          <h3 class={subtitle()}>Nezávazná kalkulace</h3>
          <p id="detail-order-close" class={text({ className: 'hover:underline cursor-pointer' })}>
            zavřít
          </p>
        </div>
        <div class="px-8 py-3">
          <div class="mb-8">
            <div class={window.location.href.includes('pujcit/') ? 'grid grid-cols-1 gap-x-10 gap-y-3 mb-2' : 'grid grid-cols-1 sm:grid-cols-2 gap-x-10 gap-y-3 mb-2'}>
              <div className="px-4 py-2 bg-slate-100 rounded">
                <p className={text({ weight: 'medium', className: 'mb-2' })}>
                  Ušetřete Čas a Peníze s Online Rezervací!
                </p>
                <p class={text({ size: 'small'})}>
                  Nezávazná cenová kalkulace s možností On-line rezervace.
                  On-line rezervace jsou rychleji zpracovány a cenově výhodnější, neboť šetří čas našich pracovníků. Avšak přijímáme i telefonické a e-mailové poptávky.
                  <br />V případě dotazu nás <span class="get-contact-btn hover:underline">kontaktujte</span>. 
                </p>
              </div>
              <div class={window.location.href.includes('pujcit/') ? 'hidden' : ''}>
                <FormSelect label="Vozidlo" name="carId" defaultValue={id} required>
                  <option value="">Vyberte</option>
                  {Object.entries(cars).map(([key, item]) => {
                    return (
                      <option key={key} value={key}>
                        {item.znacka + ' ' + item.model}
                      </option>
                    )
                  })}
                </FormSelect>
              </div>
            </div>
            <div className="sm:flex items-center border-b-[1px] border-solid border-gray-200 py-3 mb-4">
              <p className={text({ size: 'smaller', weight: 'medium', className: 'pr-6' })}>
                Poptat s možností rezervace jako:
              </p>
              <div className="flex">
                <label
                  htmlFor="form_type_0"
                  onClick={() => handleFormTypeClick('individual')}
                  className={text({
                    size: 'smaller',
                    weight: 'medium',
                    className: 'cursor-pointer mr-4',
                  })}
                >
                  <input
                    type="radio"
                    id="form_type_0"
                    className="hidden"
                    name="form_type"
                    required
                    value="Soukromá osoba"
                    defaultChecked
                  />
                  <span
                    className="inline-block py-1 px-3 hover:bg-orange-100 rounded"
                    style={getButtonStyle('individual')}
                  >
                    Soukromá osoba
                  </span>
                </label>
                <label
                  htmlFor="form_type_1"
                  onClick={() => handleFormTypeClick('company-osvc')}
                  className={text({
                    size: 'smaller',
                    weight: 'medium',
                    className: 'cursor-pointer mr-4',
                  })}
                >
                  <input
                    type="radio"
                    id="form_type_1"
                    className="hidden"
                    name="form_type"
                    required
                    value="OSVČ"
                  />
                  <span
                    className="inline-block py-1 px-3 hover:bg-orange-100 rounded"
                    style={getButtonStyle('company-osvc')}
                  >
                    OSVČ
                  </span>
                </label>
                <label
                  htmlFor="form_type_2"
                  onClick={() => handleFormTypeClick('company')}
                  className={text({
                    size: 'smaller',
                    weight: 'medium',
                    className: 'cursor-pointer mr-4',
                  })}
                >
                  <input
                    type="radio"
                    id="form_type_2"
                    className="hidden"
                    name="form_type"
                    required
                    value="Firma"
                  />
                  <span
                    className="inline-block py-1 px-3 hover:bg-orange-100 rounded"
                    style={getButtonStyle('company')}
                  >
                    Firma
                  </span>
                </label>
              </div>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 mb-4 gap-x-10 gap-y-3">
              <FormInput label="Jméno" name="firstName" autocomplete="given-name" required />
              <FormInput label="Příjmení" name="lastName" autocomplete="family-name" required />
              {selectedType === 'company' && (
                <FormInput
                  label="Název firmy"
                  name="company"
                  autocomplete="organization"
                  required
                />
              )}
              {['company', 'company-osvc'].includes(selectedType) && (
                <>
                  <FormInput label="IČ" name="companyIC" required />
                  <FormInput label="DIČ" name="companyDIC" />
                </>
              )}
              <FormInput label="Telefon" name="phone" autocomplete="phone" required />
              <FormInput label="E-mail" name="email" type="email" autocomplete="email" required />
              <FormInput
                label="Ulice a č.p."
                name="street"
                autocomplete="street-address"
                required
              />
              <FormInput label="Město" name="city" autocomplete="address-level2" required />
              <FormSelect label="Země" name="country" defaultValue="Česká republika" required>
                <option value="Česká republika">Česká republika</option>
                <option value="Slovenská republika">Slovenská republika</option>
              </FormSelect>
              <div />
              <FormInput label="Číslo ŘP" name="drivingLicense" />
              <FormInput label="Číslo OP" name="idCard" />
              <div class="sm:col-span-2 px-4 py-2 bg-slate-100 rounded mb-2">
                <p class={text({ size: 'small', weight: 'medium' })}>
                  Vyplněním ŘP a OP nyní zkrátíte proces předání vozidla o 5 minut.
                </p>
              </div>
              <FormInput
                label="Datum půjčení"
                defaultValue={dateFrom}
                onChange={e => setMinDate(e.currentTarget.value)}
                min={new Date().toISOString().slice(0, 10)}
                type="date"
                name="dateFrom"
                required
              />
              <FormTimeSelect
                label="Čas půjčení"
                name="timeFrom"
                defaultValue={timeFrom}
                required
              ></FormTimeSelect>
              <FormInput
                label="Datum vrácení"
                defaultValue={dateTo}
                min={minDate}
                type="date"
                name="dateTo"
                required
              />
              <FormTimeSelect
                label="Čas vrácení"
                name="timeTo"
                defaultValue={timeTo}
                required
              ></FormTimeSelect>
              <FormSelect label="Místo půjčení" name="cityFrom" required defaultValue={cityFrom} onChange={handleCityChange}>
                <option value="">Vyberte</option>
                <option value="1">Frýdlant n.O.</option>
                <option value="2">Frýdek-Místek</option>
                <option value="3">Ostrava</option>
                <option value="4">Valašské Meziříčí</option>
                <option value="5">Olomouc</option>
                <option value="6">Jiné</option>
              </FormSelect>
              {selectedCity === '6' && (
                <FormInput label="Zadejte místo" name="otherCity" required defaultValue={otherCity}/>
              )}
            </div>
            {data ? <Price isFetching={isFetching} {...data} /> : null}
            {data?.price?.save ? (
              <div class="flex justify-between items-center px-4 py-4 bg-indigo-100 rounded mb-2">
                <p class={text({ size: 'small', weight: 'semibold' })}>
                  Vyzvedněte si vozidlo ve Frýdlantu a ušetřete
                </p>
                <p class={text({ size: 'small', align: 'right', weight: 'semibold' })}>
                  –{czkFormatter.format(data.price.save)}
                </p>
              </div>
            ) : null}
          </div>
          <p class={text({ weight: 'bold', className: 'px-4 py-2 mb-4' })}>
            Po odeslání vaší poptávky vám na e-mail zašleme nezávaznou cenovou nabídku.
          </p>
          <p class={text({ size: 'small', className: 'px-4 py-2 bg-slate-100 rounded mb-4' })}>
            <span className="font-semibold">Pokud si budete přát provést rezervaci, je nutné uhradit zálohu.</span>&nbsp;
            Záloha obvykle činí 20% z celkové ceny, avšak minimálně 500 Kč. V případě prodlevy s platbou zálohy může již 
            být vozidlo nedostupné. V takovém případě vám nabídneme alternativní vozidlo, nebo vrátíme zálohu. V případě platby zálohy a následného zrušení rezervace je záloha nevratná. Rezervace je platná až po potvrzení z naší strany.
          </p>
          <div class="sm:flex flex-wrap items-center justify-between gap-4 mb-4">
            <p class={text({ size: 'small', className: 'mb-4 sm:mb-0' })}>
              Odesláním formuláře souhlasíte se zpracovaním osobních údajů.
            </p>
            <div class={buttonBox()}>
              <button
                class={button({ mfull: 'yes' })}
                name="offer_form"
                type="submit"
                disabled={isLoading}
              >
                Odeslat poptávku
              </button>
            </div>
          </div>
          <div class="mb-10"></div>
          {isSuccess && (
            <>
              {result.code === 'INVOICE_FAILED' && (
                <div class="px-4 py-2 bg-red-500 rounded">
                  <p class={text({ size: 'small', weight: 'semibold', color: 'white' })}>
                    Nepodařilo se vygenerovat zálohovou fakturu, opakujte akci.
                  </p>
                </div>
              )}
              {result.code === 'ERROR' && (
                <div class="px-4 py-2 bg-red-500 rounded">
                  <p class={text({ size: 'small', weight: 'semibold', color: 'white' })}>
                    {error.message}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </form>
    </Modal>
  )
}

export function OrderForm(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <Form {...props} />
    </QueryClientProvider>
  )
}
