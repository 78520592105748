import $ from 'jquery'

$('#product-slider').slick({
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 300,
  nextArrow: '#product--right',
  prevArrow: '#product--left',

  responsive: [
    {
      breakpoint: 9999,
      settings: {},
    },
    {
      breakpoint: 640,
      settings: {},
    },
  ],
})
