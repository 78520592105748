import $ from 'jquery'

// Cache selectors
const $mobileMenu = $('#mobileMenu')

$('.menu-click').on('click', openMenu)

function openMenu() {
  if ($mobileMenu.length) {
    $mobileMenu.toggleClass('hidden')
  }

  $('body').toggleClass('overflow-hidden h-screen')
}
