let slideIndex = 0
let isMouseOver = false
const slides = document.querySelectorAll('.slide')
const buttons = document.querySelectorAll('[id^="btn-slide-"]')
const links = document.querySelectorAll('[id^="slide-link-"]')
const sliderWrap = document.getElementById('slider-wrap')

function changeSlide(n) {
  slides.forEach((slide, index) => {
    slide.style.display = index === n ? 'block' : 'none'
  })
  buttons.forEach((button, index) => {
    button.classList.toggle('circle-increase--active', index === n)
  })
  links.forEach((link, index) => {
    link.style.display = index === n ? 'inline-flex' : 'none'
  })
}

function autoSlideShow() {
  if (!isMouseOver) {
    slideIndex = (slideIndex + 1) % slides.length
    changeSlide(slideIndex)
  }
  setTimeout(autoSlideShow, 2000)
}

if (sliderWrap) {
  buttons.forEach((button, index) => {
    button.addEventListener('click', event => {
      event.preventDefault()
      slideIndex = index
      changeSlide(slideIndex)
    })
  })

  sliderWrap.addEventListener('mouseover', () => (isMouseOver = true))
  sliderWrap.addEventListener('mouseout', () => (isMouseOver = false))

  // Inicializace automatické prezentace
  autoSlideShow()

  // Jednoduchý kód pro skrytí všech prvků s třídou 'slide' a zobrazení jednoho konkrétního obrázku
  document.getElementById('btn-slide-2').addEventListener('click', function () {
    document.querySelectorAll('.slide').forEach(slide => slide.classList.add('hidden'))
    document.getElementById('slide-2').classList.remove('hidden')
  })
}
