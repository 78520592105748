import { h, Fragment } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import qrcode from 'qrcode'

export function QRCodeComponent({ priceTotal, invoiceVS, iban }) {
  const [qrDataURL, setQrDataURL] = useState('')

  const platebniUdaje = `SPD*1.0*ACC:${iban}*AM:${priceTotal}.00*CC:CZK*MSG:PRONAJEM VOZIDLA*X-VS:${invoiceVS}`

  useEffect(() => {
    async function generateQR() {
      try {
        const dataURL = await qrcode.toDataURL(platebniUdaje)
        setQrDataURL(dataURL)
      } catch (err) {
        console.error(err)
      }
    }

    generateQR()
  }, [])

  return (
    <img alt="QR kód pro platbu" className="block w-full max-w-[250px] mx-auto" src={qrDataURL} />
  )
}
