import clsx from 'clsx'

const FONT = {
  default: 'font-3',
}
const SIZE = {
  default: 'text-24',
  small: 'text-20 md:text-22',
  smaller: 'text-15-22',
}
const SPACING = {
  default: 'tracking-tight',
}
const LEADING = {
  default: 'leading-5',
}
const COLOR = {
  default: 'text-black',
  primary: 'text-primary',
  secondary: 'text-gray',
  white: 'text-white',
}
const WEIGHT = {
  default: 'font-medium',
  semibold: 'font-semibold',
  light: 'font-light',
}
const ALIGN = {
  default: '',
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
  justify: 'text-justify',
}

export function subtitle({
  className = '',
  font = 'default',
  size = 'default',
  spacing = 'default',
  leading = 'default',
  color = 'default',
  weight = 'default',
  align = 'default',
} = {}) {
  return clsx(
    FONT[font],
    SIZE[size],
    SPACING[spacing],
    LEADING[leading],
    COLOR[color],
    WEIGHT[weight],
    ALIGN[align],
    className,
  )
}
