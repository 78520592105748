let czkFormatter

try {
  czkFormatter = new Intl.NumberFormat('cs-CZ', {
    style: 'currency',
    currency: 'CZK',
    maximumFractionDigits: 0,
  })
} catch (e) {
  czkFormatter = {
    format: value => `${value} Kč`,
  }
}

export { czkFormatter }

export function pluralizeDays(days) {
  if (days === 1) return 'den'
  if (days <= 4) return 'dny'
  return 'dní'
}
