import clsx from 'clsx'

const DISPLAY = {
  default: 'inline-block',
  block: 'block',
}

const PADDING = {
  default: 'py-4 xsm:py-3 px-6 box-border',
  small: 'py-3 xsm:py-2 px-5 box-border',
}

const RADIUS = {
  default: 'rounded',
}

const ICON = {
  yes: 'inline-flex justify-center items-center',
}

const HOVER = {
  default: 'transition-all duration-120 cursor-pointer',
}

const MFULL = {
  yes: 'w-full xsm:w-auto text-center',
}

const TYPE = {
  default: 'bg-primary text-white hover:brightness active:darken active:scale98',
  basic: 'bg-transparent text-black hover:underline active:scale98',
  secondary:
    'bg-transparent text-primary border-[2px] border-solid border-gray-300 hover:bg-gray-100 active:brightness active:scale98',
  tertiary: 'bg-transparent text-black hover:text-secondary',
}

export function button({
  className = '',
  display = 'default',
  type = 'default',
  padding = 'default',
  radius = 'default',
  icon = 'default',
  mfull = 'default',
  hover = 'default',
} = {}) {
  return clsx(
    DISPLAY[display],
    TYPE[type],
    PADDING[padding],
    RADIUS[radius],
    ICON[icon],
    MFULL[mfull],
    HOVER[hover],
    className,
  )
}
