import clsx from 'clsx'

const FONT = {
  default: 'font-1',
}
const SIZE = {
  default: 'text-16-22',
  bigger: 'text-18 md:text-20',
  small: 'text-[14px]',
  form: 'text-[16px]',
  medium: 'text-18-26',
  large: 'text-17-29',
  smaller: 'text-13-18',
  16: 'text-13 sm:text-14 lg:text-16',
  18: 'text-18',
  x: 'text-18 lg:text-18 xl:text-20',
}
const SPACING = {}
const LEADING = {
  default: 'leading-normal',
  none: 'leading-none',
  5: 'leading-tight',
  7: 'leading-relaxed',
}
const COLOR = {
  default: 'text-gray-light',
  primary: 'text-primary',
  secondary: 'text-secondary',
  red: 'text-red-500',
  white: 'text-white',
  'dark-blue': 'text-dark-blue',
}
const WEIGHT = {
  default: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  extrabold: 'font-extrabold',
}
const ALIGN = {
  default: '',
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
  justify: 'text-justify',
}

export function text({
  className = '',
  font = 'default',
  size = 'default',
  spacing = 'default',
  leading = 'default',
  color = 'default',
  weight = 'default',
  align = 'default',
} = {}) {
  return clsx(
    FONT[font],
    SIZE[size],
    SPACING[spacing],
    LEADING[leading],
    COLOR[color],
    WEIGHT[weight],
    ALIGN[align],
    className,
  )
}
